<template>
  <div class="Dilets">
    <div :class="listObj.caseInfo.isSell == 1 ? 'isSell' : ''">
      <div class="head" v-if="listObj.caseInfo.isSell == 1">
        <img class="icon" src="../../assets/icon9.png" alt="" />
        <div class="back" @click="$router.go(-1)">返回</div>
      </div>
      <div class="imgs" v-if="listObj.caseInfo.isSell == 1">
        <img src="../../assets/yishouk.png" alt="" />
      </div>
    </div>
    <div class="head">
      <img class="icon" src="../../assets/icon9.png" alt="" />
      <div class="back" @click="$router.go(-1)">返回</div>
    </div>
    <div v-if="datalis.showlls != 1 && listObj.caseInfo" class="sh">
      <div class="text">
        <div v-html="listObj.text"></div>
        <div
          class="foo"
          v-if="listObj.text && listObj.caseInfo.isPurchase != 1"
        >
          <div
            v-if="listObj.caseInfo.isSell == 0"
            class="exclusivity"
            @click="exclusivityClick(1)"
          >
            {{ listObj.caseInfo.price }}券·独占性购买
          </div>
          <div
            v-if="listObj.caseInfo.isSell == 0"
            class="investment"
            @click="exclusivityClick(3)"
          >
            {{ listObj.caseInfo.price / 2 }}券·投资性购买
          </div>
          <div
            v-if="listObj.caseInfo.isSell == 2"
            class="discount"
            @click="exclusivityClick(2)"
          >
            {{ listObj.caseInfo.discountPrice }}券购买
            <div class="btn"></div>
          </div>
        </div>
      </div>

      <!-- <div class="text" id="texts"></div> -->
    </div>
    <div v-if="datalis.showlls == 1">
      <div class="text">
        <h2 class="title">{{ objVal.title }}</h2>
        <h4 class="title">{{ objVal.secondTitle }}</h4>
        <p v-html="objVal.preface"></p>
        <p
          class="itemCon"
          v-for="(item, idx) in objVal.contents"
          :key="idx"
          v-html="item"
        ></p>
        <p v-html="objVal.conclusion"></p>
      </div>
      <div class="foo">投资购买</div>
    </div>
    <!-- 检验成语券弹框 -->
    <ContinuationTips
      :Tipsmessage="currentTips"
      :isShow="isShow"
      ref="ContinuationTips"
    />
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import * as echarts from 'echarts'
import ContinuationTips from 'components/ContinuationTips'
import { settings } from 'api/service'
import {
  readingCasePageList,
  caseDetail,
  checkIdiomCoupon,
  submit,
  invest,
} from 'api/Continuation'
import qs from 'qs'
export default {
  name: 'Dilets',
  //import引入的组件需要注入到对象中才能使用
  components: { ContinuationTips },
  data() {
    //这里存放数据
    return {
      currentTips: '',
      isShow: false,
      objVal: {},
      listObj: {},
      datalis: '',
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 购买
    async exclusivityClick(id) {
      this.currentTips = ''
      let res = await checkIdiomCoupon({
        type: 10,
        num:
          id == 1
            ? this.listObj.caseInfo.price
            : id == 2
            ? this.listObj.caseInfo.discountPrice
            : this.listObj.caseInfo.price / 2,
      })
      if (res.code == 200) {
        let data = await settings()
        if (data.data.aiImg == 1) {
          this.isShow = false
          if (id == 3) {
            this.invests()
            return
          }
          this.submits()
        } else {
          this.isShow = true
          this.$refs.ContinuationTips.numbers(
            id == 1
              ? this.listObj.caseInfo.price
              : id == 2
              ? this.listObj.caseInfo.discountPrice
              : this.listObj.caseInfo.price / 2,
            id
          )
        }
      } else if (res.code == 9008) {
        this.currentTips = res.message
        this.$refs.ContinuationTips.numbers('0.1997')

        this.isShow = true
      }
    },
    async invests() {
      const params = [
        {
          caseId: this.datalis.da.id,
          md5: [this.datalis.da.obj.textMd5],
        },
      ]
      let data = await invest(params)
      if (data.code == 200) {
        this.$message.success('购买成功')
        this.caseDetails()
      }
    },
    async submits() {
      const params = [
        {
          caseId: this.datalis.da.id,
          md5: [this.datalis.da.obj.textMd5],
        },
      ]
      let data = await submit(params)
      if (data.code == 200) {
        this.$message.success('购买成功')
        this.caseDetails()
      }
    },
    async caseDetails() {
      let data = await caseDetail(qs.stringify(this.datalis.da.obj))

      this.listObj = data.data
      this.$nextTick(() => {
        let regJs = ''
        this.listObj.charts.replace(
          /<script.*?>([\s\S]+?)<\/script>/gim,
          function (_, js) {
            //正则匹配出script中的内容
            regJs = js
          }
        )
        eval(regJs)
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.datalis = this.$route.query
    this.caseDetails()
    if (this.datalis.showlls == 1) {
      this.objVal = this.datalis.vals
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.sh {
  position: relative;
  padding: 12px;
  height: 80vh;
  overflow-y: auto;
}
.isSell {
  position: absolute;
  top: 0;
  padding: 15px;
  box-sizing: border-box;
  background-color: #fff;
  opacity: 0.97;
  z-index: 20;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.Dilets {
  position: relative;
  width: 100%;
  height: 90vh;
  overflow-y: auto;
  padding: 20px 50px;
  box-sizing: border-box;
  .head {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 10px 0;
    img {
      width: 8px;
      height: 15px;
      margin-right: 10px;
    }
  }
  .text {
    padding: 20px 50px 60px;
    box-sizing: border-box;
    min-height: 90vh;
    box-shadow: 0px 5px 10px 3px #ccc;
    line-height: 28px;

    h2 {
      text-align: center;
      margin-bottom: 20px;
    }
    h4 {
      text-align: center;
      margin-bottom: 20px;
    }
    p {
      text-indent: 2em;
    }
  }
  .foo {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    .exclusivity {
      cursor: pointer;
      width: 200px;
      height: 40px;
      background: linear-gradient(90deg, #ff6500, #ff9c00);
      border-radius: 20px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      line-height: 40px;
    }
    .investment {
      cursor: pointer;
      width: 200px;
      height: 40px;
      background: url('../../assets/borderjb.png') no-repeat;
      background-size: 100% 100%;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ff6600;
      text-align: center;
      line-height: 38px;
      margin-left: 29px;
    }
    .discount {
      position: relative;
      cursor: pointer;
      width: 400px;
      height: 40px;
      background: linear-gradient(90deg, #ff6500, #ff9c00);
      border-radius: 20px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      line-height: 40px;
      .btn {
        position: absolute;
        right: -50px;
        top: 10px;
        width: 45px;
        height: 20px;
        background: url('../../assets/yellow3.png') no-repeat;
      }
    }
  }
  .imgs {
    position: absolute;
    right: calc(50% - 90px);
    top: 390px;
    img {
      width: 180px;
    }
  }
}
</style>
